<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>退款管理</el-breadcrumb-item>
      <el-breadcrumb-item>处方退款</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-input
        placeholder="请输入用户编号"
        v-model="searchkeywords"
        style="width:200px;margin-right:20px;"
        clearable
        @clear="clear"
      ></el-input>
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        style="width:300px;margin-right: 20px;"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-right:10px" @click="getdata1(1)">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="Cust_Servicer" label="服务人编号"></el-table-column>
      <el-table-column prop="Cust_ID" label="用户编号"></el-table-column>
      <el-table-column label="订单编号" prop="Order_ID"></el-table-column>
      <el-table-column prop="Receiver" label="收件人姓名"></el-table-column>
      <el-table-column label="收件人手机号" prop="Tel"></el-table-column>
      <el-table-column label="收件人地址">
        <template slot-scope="scope">
          <div>
            {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.Counties
            }}{{ scope.row.Adress }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="药品明细" prop="Solution"></el-table-column>
      <el-table-column label="价格" prop="amount_str"></el-table-column>
      <el-table-column label="支付状态" prop="Pay_State">
        <template slot-scope="scope">
          <span>{{ fukuanzhuangtai(scope.row.Pay_State) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="scope">
          <div class="opt">
            <a @click="tuikuan(scope.row)">同意</a>
            <a @click="notuikuan(scope.row)">驳回</a>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      >
      </el-pagination>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      searchkeywords: "",
      date: [],
      yforderno: "",
    };
  },
  methods: {
    //付款状态
    fukuanzhuangtai(val) {
      var str = "";
      var dic = {
        "-1": "付款失败",
        0: "未付款",
        1: "已付款",
        2: "已确认",
        6: "已退款",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata1(currentPage);
    },
    getdata1(page) {
      var str = "";
      if (this.searchkeyword != "") {
        str += "&cust_id=" + this.searchkeywords;
      }
      if (this.date.length > 0) {
        str += "&create_datetime1=" + this.date[0] + "&create_datetime2=" + this.date[1];
      }
      this.axios
        .get("/order/get_orders?page=" + page + "&size=" + this.PageSize + str)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          }
        });
    },
    tuikuan(row) {
      this.$confirm("此操作将退款给患者, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
      this.axios
        .get("/docpres/get_gu_docpres_order_by_orderid?order_id=" + row.Order_ID)
        .then((res) => {
          if (res.data.code == 0) {
            this.yforderno = res.data.result[0].yf_order_no;
            console.log(this.yforderno);
            this.axios
              .post("/yf/test_pat_order_refund", this.qs.stringify({ yf_order_no: this.yforderno }))
              .then((res) => {
                if (res.data.res == "OK") {
                  this.$message({
                    message: "退款成功",
                    type: "success",
                  });
                }else{
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
          }
        });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    notuikuan(row) {},
    clear() {
      this.searchkeywords = "";
      this.list = [];
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
</style>
